import { useState } from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaUser,
  FaPhoneAlt,
  FaTelegramPlane,
} from "react-icons/fa";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { BiLoaderAlt } from "react-icons/bi";
import { FaYoutube } from "react-icons/fa";
import api from "../../services/api";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const schema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    comments: z
      .string()
      .min(10, { message: "Message must be at least 10 characters" }),
    privacy: z.coerce.boolean().refine((val) => val === true, {
      message: "You must accept the privacy policy to proceed",
    }),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      privacy: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const response = await api.post("/app/contact", {
        name: data.name,
        email: data.email,
        message: data.comments,
      });

      console.log(response);

      if (response.data.success) {
        toast.success("Message sent successfully!");
        reset();
      } else {
        throw new Error(response.data.message || "Submission failed");
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-1 md:grid-cols-2 items-baseline gap-4"
    >
      <div className="space-y-4">
        <div className="mt-2 grid grid-cols-1">
          <input
            {...register("name")}
            type="text"
            placeholder="Your name"
            className="col-start-1 row-start-1 block w-full rounded-md bg-gray-50 focus:bg-white py-1.5 pl-10 pr-3 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 sm:pl-9 text-sm/6"
          />
          <FaUser className="pointer-events-none col-start-1 row-start-1 ml-3 self-center size-4" />
          {errors.name && (
            <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
          )}
        </div>
        <div className="mt-2 grid grid-cols-1">
          <input
            {...register("email")}
            type="email"
            placeholder="Your email"
            className="col-start-1 row-start-1 block w-full rounded-md bg-gray-50 focus:bg-white py-1.5 pl-10 pr-3 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 sm:pl-9 text-sm/6"
          />
          <FaEnvelope className="pointer-events-none col-start-1 row-start-1 ml-3 self-center size-4" />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
          )}
        </div>
        <div className="hidden md:block">
          <div className="flex items-start gap-3">
            <input
              {...register("privacy", { valueAsNumber: true })}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={(e) => e.target.checked}
            />
            <p className="text-sm text-gray-900 font-medium">
              Agree to our terms, conditions according to our privacy policy
            </p>
          </div>
          {errors.privacy && (
            <p className="mt-1 text-sm text-red-500">
              {errors.privacy.message}
            </p>
          )}
        </div>
      </div>
      <div className="space-y-3">
        <div>
          <textarea
            {...register("comments")}
            placeholder="Comments"
            className="resize-none block w-full h-40 rounded-md bg-gray-50 focus:bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 sm:text-sm/6"
          />
          {errors.comments && (
            <p className="mt-1 text-sm text-red-500">
              {errors.comments.message}
            </p>
          )}
        </div>
        <div className="block md:hidden">
          <div className="flex items-start gap-3">
            <input
              {...register("privacy")}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <p className="text-sm text-gray-900 font-medium">
              Agree to our terms, conditions according to our privacy policy
            </p>
          </div>
          {errors.privacy && (
            <p className="mt-1 text-sm text-red-500">
              {errors.privacy.message}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="text-[14px] inline-flex items-center gap-x-2 rounded-md bg-color-primary-bright-jade px-4 py-2.5 font-semibold text-white shadow-sm hover:bg-color-primary-bright-jade/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-primary-bright-jade"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <BiLoaderAlt className="animate-spin size-4" /> Sending
            </>
          ) : (
            <>
              <FaTelegramPlane className="-ml-0.5 size-4" />
              Send Message
            </>
          )}
        </button>
      </div>
    </form>
  );
};

const ContactUs = () => {
  return (
<div class="flex flex-col justify-center">
    <div
      className="bg-no-repeat bg-cover pb-14"
      style={{
        backgroundImage: "url(/background_waves_contact.png)",
      }}
    >
      <div className="mx-auto container px-4 sm:px-6 lg:px-8 space-y-16">
        <section className="grid lg:grid-cols-2 gap-8 md:gap-12 items-start">
          <div className="space-y-8">
            <div>
              <h1 className="text-[3rem] lg:text-[72px] font-bold leading-tight text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                Contact Us
              </h1>
              <h2 className="text-2xl">Let&apos;s start a conversation!</h2>
            </div>

            <p className="text-xl text-gray-600">
              We would love to connect with you to answer all your questions and
              provide all the information you need.
            </p>
            <ContactForm />
            <div
              className="bg-white rounded-xl shadow-lg p-4 sm:p-6 space-y-6 sm:space-y-8"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <div className="space-y-1">
                <h3 className="text-2xl font-bold">Give us a call</h3>
                <div>
                  <a href="tel:97142986553">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-2 rounded-md bg-color-primary-bright-jade px-4 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-color-primary-bright-jade/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-primary-bright-jade"
                    >
                      <FaPhoneAlt
                        aria-hidden="true"
                        className="-ml-0.5 size-4"
                      />
                      +971 4 298 6553
                    </button>
                  </a>
                </div>
              </div>
              <div className="space-y-2">
                <h3 className="text-2xl font-bold">Our address</h3>
                <div className="space-y-1 text-gray-600">
                  <p>Unit 201, Level 1</p>
                  <p>Gate Avenue South Zone</p>
                  <p>Dubai International Financial Centre</p>
                  <p>Dubai, UAE</p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex justify-center">
            <img src="/mobile_contact.png" className="h-fit" />
          </div>
        </section>
        <section className="grid grid-cols-1 md:grid-cols-2">
          <div />
          <div className="text-center space-y-8">
            <h3 className="text-3xl font-bold text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
              Want to stay in the loop?
            </h3>
            <div>
              <a
                href="/register"
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FaChevronRight className="-ml-0.5 size-5" />
                Join The Waiting List
              </a>
            </div>
          </div>
        </section>
        <section className="w-full flex justify-center">
  <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
    <h4 className="font-bold text-2xl">Connect with us on social media</h4>
    <div className="flex items-center gap-8 mt-4">
      <a
        href="https://www.linkedin.com/company/3-shepherd/"
        target="_blank"
        rel="noopener noreferrer"
        className="size-7"
      >
        <img alt="LinkedIn" src="/linekdin-icon.svg" />
      </a>
      <a
        href="https://www.youtube.com/@3-Shepherd"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube className="size-8" />
      </a>
    </div>
  </div>
</section>


      </div>
    </div>
    </div>
  );
};

export default ContactUs;
