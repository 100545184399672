import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GoogleLogin } from "@react-oauth/google";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useAuth } from "../../components/AuthProvider";
import api from "../../services/api";
import { jwtDecode } from "jwt-decode";

function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login, googleLogin } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      console.log("Starting login process for:", email);

      if (!email) {
        toast.error("Please enter your email");
        setIsLoading(false);
        return;
      }

      // We only need email for MSAL login
      const userData = await login(email, { loginHint: email });
      console.log("Login successful, processing response");

      if (!userData || !userData.user) {
        throw new Error("No user data received from authentication");
      }

      const user = userData.user;
      console.log("Received user role:", user.role);

      // Handle navigation based on role
      switch (user.role.toLowerCase()) {
        case "owner":
        case "shipping":
        case "shipping":
        case "merchant":
          navigate("/panel", { replace: true });
          break;
        case "user":
          navigate("/userpanel", { replace: true });
          break;
        case "admin":
          navigate("/adminpanel", { replace: true });
          break;
        default:
          throw new Error(`Invalid user role: ${user.role}`);
      }
    } catch (error) {
      console.error("Login error:", error);

      // Detailed error handling
      if (error.errorCode === "user_cancelled") {
        toast.error("Login was cancelled");
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Login failed - please try again");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const userData = await googleLogin(response.credential);

      if (!userData || !userData.user) {
        throw new Error("No user data received from Google authentication");
      }

      const user = userData.user;

      if (user.role === "merchant") {
        navigate("/panel", { replace: true });
      } else {
        navigate("/userpanel", { replace: true });
      }

      toast.success(`Welcome back, ${user.name}!`);
    } catch (error) {
      console.error("Google sign-in error:", error);
      toast.error(error.message || "Google sign-in failed");
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google sign-in failed:", error);
    toast.error("Google sign-in failed. Please try again.");
  };

  const handleUaePassSignIn = () => {
    window.location.href =
      "https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/auth/uaepass";
  };

  const handlePasswordReset = () => {
    const redirectUri = `${window.location.origin}`;
    const tenant = "3shep";
    const policy = "B2C_1_pwd_reset";
    const clientId = process.env.REACT_APP_AZURE_B2C_CLIENT_ID;

    const b2cPasswordResetUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=id_token&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=fragment&scope=openid&nonce=${Math.random().toString(36)}`;

    window.location.href = b2cPasswordResetUrl;
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#08C989" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {/* <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
          />
          <Typography variant="body1" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
            ----------- Or continue with email -----------
          </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Sign In with Email"
              )}
            </Button>
            {/* <button
              onClick={handleUaePassSignIn}
              className="uae-pass-button"
              style={{
                width: "100%",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <img
                src="/UAEPASS_Sign_in.svg"
                alt="UAE Pass Login"
                style={{ width: "100%", height: "auto" }}
              />
            </button> */}
            <Grid container>
              <Grid item xs>
                <Link
                  component="button"
                  variant="body2"
                  onClick={handlePasswordReset} // Replace href with onClick
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

function UaePassCallbackHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleUaePassCallback } = useAuth();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const processCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const token = params.get("token");
        const email = params.get("email");
        const name = params.get("name");
        const role = params.get("role");

        const success = await handleUaePassCallback(token, email, name, role);
        if (!success) {
          navigate("/login", { replace: true });
        }
      } catch (error) {
        console.error("UAE Pass callback error:", error);
        toast.error("Authentication failed. Please try again.");
        navigate("/login", { replace: true });
      } finally {
        setIsProcessing(false);
      }
    };

    processCallback();
  }, [location, navigate, handleUaePassCallback]);

  if (isProcessing) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={2}
      >
        <CircularProgress size={40} />
        <Typography variant="h6" color="textSecondary">
          Completing your login...
        </Typography>
      </Box>
    );
  }

  return null;
}
export { SignIn, UaePassCallbackHandler };
