import React, { useEffect, useState } from "react";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Avatar,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";

import LicenseVerification from "./license-verification";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../AuthProvider"; // Adjust path as needed
import BusinessVerification from "./business-verification";
import BankDetails from "./bank-details";

const businessSteps = [
  "Account Setup",
  "Bank Details Verification",
  "License Verification",
  "Identity Verification",
];

export default function MerchantVerification() {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  // const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  console.log("MERCHANT USER +>", user);
  console.log("MERCHANT LOADING +>", isLoading);

  useEffect(() => {
    if (!isLoading) {
      if (user && user.role !== "user") {
        if (user.merchant_verification_status === "VERIFIED") {
          toast.error("Your account is already verified");
          navigate("/panel");
        } else if (user.merchant_verification_status === "PENDING") {
          setActiveStep(1);
        } else if (
          user.merchant_verification_status === "BANK_DETAILS_VERIFIED"
        ) {
          setActiveStep(2);
        } else if (user.merchant_verification_status === "LICENSE_VERIFIED") {
          setActiveStep(3);
        }
      } else {
        toast.error("You are not authorized to view this page");
        navigate("/userpanel");
      }
    }
  }, [isLoading, user]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <div>
            <CircularProgress className="animate-spin" />
          </div>
        ) : (
          <>
            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
              Verification
            </Typography>

            <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
              {businessSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              {activeStep === 1 && (
                <Paper elevation={3} sx={{ p: 3 }}>
                  <BankDetails user={user} setActiveStep={setActiveStep} />
                </Paper>
              )}

              {activeStep === 2 && (
                <Paper elevation={3} sx={{ p: 3 }}>
                  <LicenseVerification
                    user={user}
                    setActiveStep={setActiveStep}
                  />
                </Paper>
              )}

              {activeStep === 3 && (
                <Paper elevation={3} sx={{ p: 3 }}>
                  <BusinessVerification user={user} />
                </Paper>
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}
