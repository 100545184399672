import React, { useState, useEffect, useRef } from "react";
import { X, Send, Bot } from "lucide-react";
import { motion } from "framer-motion";
import api from "../../services/api";

const ChatbotPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Hello! I'm The Shepherd, your AI Assistant. How can I assist you today?",
      sender: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const chatEndRef = useRef(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { text: input, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await api.post(
        "/chatbot/chat",
        { message: input },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Extract message properly
      const botResponse = response.data.response;
      const botText =
        botResponse?.[0]?.text?.value || "Error: Unexpected response format";

      const botMessage = { text: botText, sender: "bot" };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Error: Unable to reach chatbot.", sender: "bot" },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents newline on mobile keyboards
      handleSendMessage();
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="fixed bottom-4 right-4 md:bottom-8 md:right-8 z-50">
      {!isOpen && (
        <motion.button
          onClick={togglePopup}
          className="p-1 rounded-full  hover:scale-110 transition-transform"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <img
            src="/chatbot-icon.png"
            alt="chatbot-icon"
            className="w-20 h-20 md:w-24 md:h-24 rounded-full object-contain"
          />
        </motion.button>
      )}

      {isOpen && (
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          className="w-[90vw] max-w-md h-[60vh] md:h-[80vh] md:w-[420px]
                     bg-white shadow-2xl rounded-2xl flex flex-col
                     border border-gray-200 overflow-hidden mt-16"
        >
          {/* Header */}
          <div className="relative flex justify-between items-center px-4 py-3 border-b">
            <h2 className="text-lg font-semibold text-gray-800">
              The Shepherd
            </h2>
            <button
              onClick={togglePopup}
              className="w-8 h-8 flex items-center justify-center text-gray-500 hover:text-red-500 transition rounded-full"
            >
              <X size={22} />
            </button>
          </div>

          {/* Chat Area */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((msg, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className={`p-4 text-base rounded-lg max-w-[80%] leading-relaxed ${
                  msg.sender === "user"
                    ? "bg-blue-500 text-white ml-auto"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {msg.text}
              </motion.div>
            ))}
            {loading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-gray-500 italic text-base"
              >
                Typing...
              </motion.div>
            )}
            <div ref={chatEndRef} />
          </div>

          {/* Input Box */}
          <div className="border-t p-3 flex items-center gap-3 bg-white">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown} // <-- Capture Enter key
              placeholder="Type a message..."
              className="flex-1 p-3 border rounded-lg text-base
                         focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <motion.button
              onClick={handleSendMessage}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="w-10 h-10 flex items-center justify-center
                         bg-gradient-to-r from-blue-500 to-green-400
                         text-white rounded-full
                         hover:bg-blue-600 transition"
            >
              <Send size={22} />
            </motion.button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ChatbotPopup;
