import WPHeader from "../header/WPheader";
import PropTypes from "prop-types";

const FrameComponent4 = ({ className = "", children }) => {
  return (
    // To Change padding modify pb
    <header
      className={`self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[50px] box-border sticky gap-[20px] top-[0] z-[99] max-w-full text-left text-base text-color-text-charcoal font-callout-body ${className}`}
    >
      <div className="self-stretch h-0 relative" />
      <div className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(180deg,_#fff,_#fff_19.27%,_rgba(255,_255,_255,_0))] " />
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[15px] md:px-[30px] box-border max-w-full">
        <WPHeader>{children}</WPHeader>
      </div>
    </header>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FrameComponent4;