import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom"
import { styled } from '@mui/material/styles';

import AddressForm from './AddressForm';
import Review from './Review';
import PaymentForm1 from './PaymentForm1';
import {
  CssBaseline,
  AppBar,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Link,
  Typography,
  Container,
  Box
} from '@mui/material';
import { toast } from 'react-toastify';
import api from '../../services/api';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        3-Shepherd Tech Limited.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const logoURL = 'https://i.postimg.cc/2yvkxf7G/color-logo-no-background-1-2x.png';
const payslogo = 'https://i.postimg.cc/cJxM58KC/pay-with-3s-icon-logo-2x.png';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2)
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

const StepperStyled = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(3, 0, 5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0, 3),
    '& .MuiStepLabel-label': {
      fontSize: '0.875rem',
    },
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > button': {
      width: '100%',
    },
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
    '& img': {
      height: '80px',
    },
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '100px',
  marginRight: '-10px',
  [theme.breakpoints.down('sm')]: {
    height: '80px',
    marginRight: 0,
  },
}));

const steps = ['Shipping address', 'Review your order', 'Payment details'];

export default function Checkout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [isAddressValid, setIsAddressValid] = useState(false);
  
  const [formData, setFormData] = useState({
    user_id: '',
    merchant_id: null,
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });

  const [shippingRate, setShippingRate] = useState(null);
  const [transitTime, setTransitTime] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [selectedService, setSelectedService] = useState('');


  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleSubmit = () => setActiveStep(activeStep + 1);
  const handleAddressValidation = (isValid) => setIsAddressValid(isValid);


  useEffect(() => {
    if(!searchParams.get("token")) {
      toast.error("Invalid checkout link. Please try again.");
      navigate("/userpanel", { replace: true });
    } else {
      (async() =>{
        const token = searchParams.get("token");
        const response = await api.get(`/checkout/details/${token}`);
        
        setFormData((prev) => ({
          ...prev,
          merchant_id: response.data.merchantId,
          products: response.data.products,
          total_amount: response.data.total_amount
        }));
      })()
    }
    
  }, [])
  

  console.log(formData);
  
  

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm 
          formData={formData} 
          setFormData={setFormData} 
          onValidationChange={handleAddressValidation}
        />;
      case 1:
        return (
          <Review
            formData={formData}
            shippingRate={shippingRate}
            transitTime={transitTime}
            setShippingRate={setShippingRate}
            setTransitTime={setTransitTime}
            setClientSecret={setClientSecret}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
        );
      case 2:
        return <PaymentForm1 
          formData={formData} 
          clientSecret={clientSecret} 
          handleSubmit={handleSubmit} 
          shippingRate={shippingRate} 
          setPaymentStatus={setPaymentStatus} 
          selectedService={selectedService}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBarStyled color="default" elevation={0} />
      <StyledContainer maxWidth="sm">
        <PaperStyled elevation={3}>
          <HeaderContainer>
            <LogoImage 
              src={payslogo} 
              alt="3-Shepherd Logo"
            />
            <Typography 
              component="h1" 
              variant="h4" 
              align="center"
              sx={{
                fontSize: { xs: '1.5rem', sm: '2rem' }
              }}
            >
              Pay with 3S
            </Typography>
          </HeaderContainer>

          <StepperStyled activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </StepperStyled>

          {getStepContent(activeStep)}

          <ButtonContainer>
            {activeStep !== 0 && (
              <Button
                onClick={handleBack}
                variant="outlined"
                sx={{ minWidth: { xs: '100%', sm: 'auto' } }}
              >
                Back
              </Button>
            )}
            {activeStep !== steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={
                  (activeStep === 0 && !isAddressValid) || 
                  (activeStep === 1 && (!shippingRate || !selectedService))
                }
                sx={{ minWidth: { xs: '100%', sm: 'auto' } }}
              >
                Next
              </Button>
            )}
          </ButtonContainer>
        </PaperStyled>
        <Copyright />
      </StyledContainer>
    </React.Fragment>
  );
}