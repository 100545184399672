import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';

// Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import api from '../../services/api';
import { ShoppingBag } from 'lucide-react';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const location = useLocation();
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);
  const { getUser } = useAuth();
  const userData = getUser();

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        if (!userData?.organizationId) {
          setLoading(false);
          return;
        }

        const response = await api.get(`/organizations/${userData.organizationId}`);
        
        if (response.data && response.data.data) {
          setBusinessName(response.data.data.business_name);
        }
      } catch (error) {
        setBusinessName('');
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessName();
  }, [userData]);

  useEffect(() => {
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    setSidebarExpanded(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');
  }, []);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
  }, [sidebarExpanded]);

  return (
    <>
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
        onClick={() => setSidebarOpen(false)}
      ></div>

      <div
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
        <Link to="/panel" className="block">
  <div className={`text-white text-xl font-bold lg:hidden lg:sidebar-expanded:block 2xl:block`}>
    {businessName}
  </div>
</Link>
          <button
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(false)}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        <div className="space-y-8">
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6">•••</span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Main</span>
            </h3>
            <ul className="mt-3">
  {/* Dashboard - show only if not finance role */}
  {userData?.role !== 'finance' && (
    <li className="px-3 py-3">
      <Link
        to="/panel"
        className={`block text-slate-200 truncate transition duration-150 ${
          location.pathname === '/panel' ? 'hover:text-slate-200' : 'hover:text-white'
        }`}
      >
        <div className="flex items-center">
          <DashboardIcon className="shrink-0 h-7 w-7" />
          <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
            Dashboard
          </span>
        </div>
      </Link>
    </li>
  )}

  {userData?.role !== 'user' && (
    <li className="px-3 py-3">
      <Link
        to="/checkout-link"
        className={`block text-slate-200 truncate transition duration-150 ${
          location.pathname === '/checkout-link' ? 'hover:text-slate-200' : 'hover:text-white'
        }`}
      >
        <div className="flex items-center">
          <ShoppingBag className="size-5" />
          <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
            Checkout Link
          </span>
        </div>
      </Link>
    </li>
  )}

  {/* Orders - show for all roles */}
  <li className="px-3 py-3">
    <Link
      to="/merchant-orders"
      className={`block text-slate-200 truncate transition duration-150 ${
        location.pathname === '/merchant-orders' ? 'hover:text-slate-200' : 'hover:text-white'
      }`}
    >
      <div className="flex items-center">
        <StoreIcon className="shrink-0 h-7 w-7" />
        <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
          Orders
        </span>
      </div>
    </Link>
  </li>

  {/* MerchantFlow - show for all roles */}
  <li className="px-3 py-3">
    <Link
      to="/merchant-orderflow"
      className={`block text-slate-200 truncate transition duration-150 ${
        location.pathname === '/merchant-orderflow' ? 'hover:text-slate-200' : 'hover:text-white'
      }`}
    >
      <div className="flex items-center">
        <StoreIcon className="shrink-0 h-7 w-7" />
        <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
          Order Flow
        </span>
      </div>
    </Link>
  </li>

  {/* Show other menu items only if not finance role */}
  {userData?.role !== 'finance' && (
    <>
      {/* Request Pickup */}
      <li className="px-3 py-3">
        <Link
          to="/requestpickup"
          className={`block text-slate-200 truncate transition duration-150 ${
            location.pathname === '/requestpickup' ? 'hover:text-slate-200' : 'hover:text-white'
          }`}
        >
          <div className="flex items-center">
            <LocalShippingIcon className="shrink-0 h-7 w-7" />
            <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Request Pickup
            </span>
          </div>
        </Link>
      </li>

      {/* Create Shipment */}
      <li className="px-3 py-3">
        <Link
          to="/createshipment"
          className={`block text-slate-200 truncate transition duration-150 ${
            location.pathname === '/createshipment' ? 'hover:text-slate-200' : 'hover:text-white'
          }`}
        >
          <div className="flex items-center">
            <CreditCardIcon className="shrink-0 h-7 w-7" />
            <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Create Shipment
            </span>
          </div>
        </Link>
      </li>
    </>
  )}

  {/* Settings and Profile - show only if not shipping or finance role */}
  {userData?.role !== 'shipping' && userData?.role !== 'finance' && (
    <>
      {/* Settings */}
      <li className="px-3 py-3">
        <Link
          to="/settings"
          className={`block text-slate-200 truncate transition duration-150 ${
            location.pathname === '/settings' ? 'hover:text-slate-200' : 'hover:text-white'
          }`}
        >
          <div className="flex items-center">
            <SettingsApplicationsIcon className="shrink-0 h-7 w-7" />
            <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Settings
            </span>
          </div>
        </Link>
      </li>

      {/* Profile */}
      <li className="px-3 py-3">
        <Link
          to="/merchant-profile"
          className={`block text-slate-200 truncate transition duration-150 ${
            location.pathname === '/complete-merchant-profile' ? 'hover:text-slate-200' : 'hover:text-white'
          }`}
        >
          <div className="flex items-center">
            <AccountCircleOutlinedIcon className="shrink-0 h-7 w-7" />
            <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Account
            </span>
          </div>
        </Link>
      </li>
    </>
  )}
</ul>
          </div>
        </div>

        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="text-slate-500 hover:text-slate-400">
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                  <path
                    d="M12 16c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                    className={`transform transition-transform duration-200 ${sidebarExpanded ? 'rotate-90' : ''}`}
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;