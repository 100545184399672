"use client";

import { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Trash2, Plus, Loader2 } from "lucide-react";
import { Container, Box } from "@mui/material";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { useAuth } from "../AuthProvider";
import api from "../../services/api";

const productSchema = z.object({
  name: z
    .string({ required_error: "This field is required" })
    .min(1, "Required"),
  price: z
    .number({ required_error: "This field is required" })
    .positive("Must be positive"),
  quantity: z
    .number({ required_error: "This field is required" })
    .min(1, "Must be at least 1"),
  productDetailsLink: z
    .string({ required_error: "This field is required" })
    .min(1, "Required"),
});

const formSchema = z.object({
  products: z.array(productSchema).min(1, "At least one product is required"),
});

export default function CheckoutLinkForm() {
  const { user } = useAuth();
  const [generatedLink, setGeneratedLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      products: [{ name: "", price: 0, quantity: 0, productDetailsLink: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const onSubmit = async (data) => {
    try {
      setGeneratedLink(null);
      setLoading(true);
      const response = await api.post("/checkout/generate-checkout-token", {
        merchantId: user.id,
        ...data,
      });

      setGeneratedLink(
        `${window.location.origin}/checkout?token=${response.data.token}`
      );
    } catch (error) {
      console.error("Error creating link:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <div className="w-full max-w-3xl mx-auto p-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="bg-white rounded-lg px-2 py-1 rounded border border-gray-300  overflow-hidden"
            >
              <div className="p-6 space-y-4">
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <div className="flex flex-wrap items-start gap-2">
                      <div className="flex-1 min-w-[200px] space-y-1">
                        <label
                          htmlFor={`product-name-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Name
                        </label>
                        <input
                          id={`product-name-${index}`}
                          {...register(`products.${index}.name`)}
                          placeholder="Product Name"
                          className="w-full px-2 py-1 rounded border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.products?.[index]?.name && (
                          <p className="text-xs text-red-600">
                            {errors.products[index]?.name?.message}
                          </p>
                        )}
                      </div>

                      <div className="w-24 space-y-1">
                        <label
                          htmlFor={`product-price-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price
                        </label>
                        <input
                          id={`product-price-${index}`}
                          type="number"
                          placeholder="Price"
                          {...register(`products.${index}.price`, {
                            valueAsNumber: true,
                          })}
                          className="w-full px-2 py-1 rounded border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.products?.[index]?.price && (
                          <p className="text-xs text-red-600">
                            {errors.products[index]?.price?.message}
                          </p>
                        )}
                      </div>

                      <div className="w-24 space-y-1">
                        <label
                          htmlFor={`product-quantity-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Quantity
                        </label>
                        <input
                          id={`product-quantity-${index}`}
                          type="number"
                          placeholder="Quantity"
                          {...register(`products.${index}.quantity`, {
                            valueAsNumber: true,
                          })}
                          className="w-full px-2 py-1 rounded border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.products?.[index]?.quantity && (
                          <p className="text-xs text-red-600">
                            {errors.products[index]?.quantity?.message}
                          </p>
                        )}
                      </div>

                      <div className="flex-1 min-w-[200px] space-y-1">
                        <label
                          htmlFor={`product-details-link-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Details Link
                        </label>
                        <input
                          id={`product-details-link-${index}`}
                          {...register(`products.${index}.productDetailsLink`)}
                          placeholder="Product Details Link"
                          className="w-full px-2 py-1 rounded border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.products?.[index]?.productDetailsLink && (
                          <p className="text-xs text-red-600">
                            {
                              errors.products[index]?.productDetailsLink
                                ?.message
                            }
                          </p>
                        )}
                      </div>

                      {fields.length > 1 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="p-1 text-red-600 hover:bg-red-100 rounded self-start mt-6"
                        >
                          <Trash2 size={20} />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-between p-4 bg-gray-50 border-t">
                <button
                  type="button"
                  onClick={() =>
                    append({
                      name: "",
                      price: 0,
                      quantity: 0,
                      productDetailsLink: "",
                    })
                  }
                  className="flex items-center px-4 py-2 text-sm text-indigo-600 border border-indigo-600 rounded hover:bg-indigo-100"
                >
                  <Plus size={20} />
                </button>
                <button
                  disabled={loading}
                  type="submit"
                  className="px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-700"
                >
                  {loading ? (
                    <Loader2 className="animate-spin size-4" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>

            {generatedLink && (
              <div className="mt-4 p-4 bg-green-100 rounded">
                <p className="font-medium">Generated Link:</p>
                <a
                  href={generatedLink}
                  target="_blank"
                  className="text-blue-600 break-all hover:underline"
                  rel="noreferrer"
                >
                  {generatedLink}
                </a>
              </div>
            )}
          </div>
        </Container>
      </Box>
    </Box>
  );
}
