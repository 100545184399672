import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./global.css";
import { ThemeProvider } from '@mui/material/styles';
import { ColorModeContext, useMode } from './theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const Root = () => {
  const [theme, colorMode] = useMode();
  return (
    <ThemeProvider theme={theme}>
      {/* <ErrorBoundary> */}
        <App />
      {/* </ErrorBoundary> */}
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// Register service worker (needed for installability)
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
reportWebVitals();