import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';  
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import api from '../../services/api';
import { FaExternalLinkAlt } from "react-icons/fa";

const serviceTypeMapping = {
  "FEDEX_GROUND": "FedEx Ground",
  "FEDEX_2_DAY": "FedEx 2 Day",
  "STANDARD_OVERNIGHT": "FedEx Standard Overnight",
  // Add other mappings as necessary
};

const Review = ({ formData, shippingRate, setShippingRate, setClientSecret, selectedService, setSelectedService  }) => {
  const [totalPrice, setTotalPrice] = useState(formData.total_amount);
  const [error, setError] = useState(null);
  const [availableServices, setAvailableServices] = useState([]);

  useEffect(() => {
    const fetchServiceAvailability = async () => {
      try {
        const response = await api.post('/fedex/service-availability', {
          recipientPostalCode: formData.zip,
          recipientCountryCode: formData.country,
          products: formData.products,
          merchantId: formData.merchant_id
        });

        if (response.data.output && response.data.output.transitTimes) {
          setAvailableServices(response.data.output.transitTimes);
        }
      } catch (error) {
        console.error('Error fetching service availability:', error);
        setError("Failed to fetch available services.");
        // **Set 'FedEx Ground' as the available service**

        setAvailableServices([{
          transitTimeDetails: [{
            serviceType: 'FEDEX_GROUND',
            commit: {
              transitDays: {
                description: 'N/A'
              }
            }
          }]
        }]);
  
        // **Set 'FEDEX_GROUND' as the selected service**
        setSelectedService('FEDEX_GROUND');
  
        // **Optionally, fetch the shipping rate for 'FedEx Ground'**
        handleServiceChange({ target: { value: 'FEDEX_GROUND' } });
      }
    };
  
    fetchServiceAvailability();
  }, [formData.zip, formData.country]);

  const handleServiceChange = async (event) => {
    const serviceType = event.target.value;
    setSelectedService(serviceType); // Pass the selected service to the parent component
    console.log('Selected Service:', serviceType); // Debugging line
    try {
      const response = await api.post('/fedex/get-rate', {
        recipientPostalCode: formData.zip,
        recipientCountryCode: formData.country,
        merchantId: formData.merchant_id,
        products: formData.products,
        serviceType
      });
      
      const { rate } = response.data;
      setShippingRate(rate);
      setTotalPrice(totalPrice + rate);
    } catch (error) {
      console.error('Error fetching shipping rate:', error);
      setError("Failed to fetch shipping rate.");
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" gutterBottom>Order Summary</Typography>
        <div className='space-y-1'>
          {formData.products.map((product) => (
            <div key={product.name} className='space-y-1'>
              <div className='flex items-center gap-1'>
                <p>{product.name}</p> 
                <a href={product.productDetailsLink} target='_blank' rel='noreferrer'>
                  <FaExternalLinkAlt />
                </a>
              </div>
              <p>${product.price.toFixed(2)}</p>
            </div>
          ))}
        </div>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Available Shipping Services</Typography>
            <RadioGroup value={selectedService} onChange={handleServiceChange}>
              {availableServices.map((service, index) => (
                <FormControlLabel
                  key={index}
                  value={service.transitTimeDetails[0].serviceType}
                  control={<Radio />}
                  label={`${serviceTypeMapping[service.transitTimeDetails[0].serviceType] || service.transitTimeDetails[0].serviceType} (${service.transitTimeDetails[0].commit.transitDays.description})`}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" >Shipping Rate</Typography>
            <Typography variant="body2" sx={{ textAlign: 'right' }}>
              {shippingRate !== null ? `$${shippingRate.toFixed(2)}` : 'Calculating...'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" >Total</Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>${totalPrice.toFixed(2)}</Typography>
          </Grid>
        </Grid>
        
      </CardContent>
    </Card>
  );
};

export default Review;