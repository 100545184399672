import { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";

const WPHeader = ({ className = "", children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const onMenuItemContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='fold']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);
  
  return (
    <div className="relative w-full">
      {/* To Change Header width modify py */}
      <div
        className={`shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] bg-color-background-white 
          flex items-center justify-between z-[2] text-base text-color-text-charcoal font-callout-body ${className} py-1 px-4 rounded-2xl ${
          isMenuOpen && "rounded-b-none md:rounded-b-2xl"
        }`}
      >
        {/* Logo Section */}
        <Link to="/" className="no-underline">
          <div className="flex flex-row items-center justify-start gap-[15px]">
            <img
              className="h-12 w-12 relative object-cover"
              loading="lazy"
              alt="3S Icon"
              src="/3s-logo-icon-only@2x.png"
            />
            <img
              className="h-5 w-[140.9px] relative object-contain"
              loading="lazy"
              alt="3S Logo"
              src="/Color logo - no background 1.png"
            />
          </div>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden md:flex flex-row items-center justify-end gap-8">
          {children}
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden cursor-pointer"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <IoMdClose className="size-7 text-color-text-charcoal" />
          ) : (
            <RxHamburgerMenu className="size-7 text-color-text-charcoal" />
          )}
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      <div
        className={`absolute top-full left-0 right-0 bg-color-background-white shadow-lg rounded-b-2xl 
          overflow-hidden transition-all duration-300 ease-in-out z-[1] md:hidden
          ${isMenuOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}`}
      >
        <div className="flex flex-col items-start w-full py-2">
          {Array.isArray(children) ? (
            children.map((child, index) => (
              <div
                key={index}
                className="w-full px-5 py-3 hover:bg-gray-50 transition-colors duration-200"
              >
                {child}
              </div>
            ))
          ) : (
            <div className="w-full px-5 py-3 hover:bg-gray-50 transition-colors duration-200">
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WPHeader;
