import { FaChevronRight, FaChevronDown, FaCheck } from "react-icons/fa";
import { useScroll, useTransform, motion, useSpring } from "framer-motion";
import ChatbotPopup from "../components/chatbot/chatbot";
import { FaYoutube } from "react-icons/fa";

export const HowItWorksCard = ({ title, desc, color }) => {
  return (
    <div
      className={`rounded-xl p-4 md:p-6 text-white space-y-4 ${
        color === "blue" && "bg-indigo-600"
      } ${color === "sky" && "bg-color-primary-sky-blue"} ${
        color === "jade" && "bg-color-primary-bright-jade"
      }`}
    >
      <div className="flex justify-center">
        <FaCheck className="size-6" />
      </div>
      <div className="space-y-2">
        <h3 className="text-xl font-medium">{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

const Home = () => {
  const { scrollY } = useScroll();

  const translateY = useTransform(scrollY, [0, 500], [200, -50]); // Reduced movement range
  const smoothTranslateY = useSpring(translateY, {
    stiffness: 120, // Lower stiffness for smoother motion
    damping: 30, // Higher damping to reduce wiggle
    mass: 1,
  });

  return (
    <div className="space-y-36">
      <div
        className="bg-cover bg-no-repeat"
        style={{
          backgroundImage: "url(/background-waves.svg)",
          backgroundPosition: "0px 82px",
        }}
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-6xl">
            <section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="space-y-8">
                <h1 className="text-[3rem]  font-bold leading-tight">
                  Feel{" "}
                  <span className="relative inline-block">
                    secure
                    <span className="absolute left-[-0.2em] right-[-0.2em] bottom-0 h-[40%] !bg-emerald-200/70 -z-10"></span>
                  </span>{" "}
                  while transacting online
                </h1>
                <h4 className="font-medium text-xl md:text-[20px] text-gray-600 leading-relaxed">
                  Protect your money with 3 Shepherd - the only service that
                  secures your payments and goods.
                </h4>
                <div>
                  <a
                    href="#howitworks"
                    type="button"
                    className="rounded-md bg-color-primary-bright-jade px-4 py-3 text-[14px] font-semibold text-white shadow-sm hover:bg-color-primary-bright-jade/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-primary-bright-jade"
                  >
                    See how it works
                  </a>
                </div>
              </div>
              <div className="space-y-4">
                <img src="/correct-segment-2.svg" alt="correct-segment-2" />
                <img src="/component-2.svg" alt="component-2" />
                <img src="/correct-segment-1.svg" alt="correct-segment-1" />
              </div>
            </section>
            <section className="flex flex-col sm:flex-row items-center gap-8 mb-12 md:mb-0">
              <div
                className="flex items-center justify-center rounded-2xl bg-white size-20"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <img
                  src="/3s-logo-icon-only-1@2x.png"
                  alt="3s-logo-icon-only-1@2x"
                  className="size-16"
                />
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-bold capitalize">
                  Pay with 3S
                </h3>
                {/* <h4 className="text-base md:text-lg text-gray-500 tracking-widest uppercase">
                  coming soon
                </h4> */}
              </div>
              <a
                href="#howitworks"
                className="capitalize inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-4 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FaChevronRight className="-ml-0.5 size-5" />
                Find out more
              </a>
            </section>
            <section className="grid grid-cols-1 md:grid-cols-2 gap-8 md:mt-[-100px] lg:mt-[-200px]">
              <div className="space-y-6 sm:w-1/2 flex flex-col justify-end">
                <div
                  className="flex items-center justify-center rounded-2xl bg-white size-28"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    transform: "rotateZ(320deg)",
                  }}
                >
                  <img
                    src="/3s-logo-icon-only-1@2x.png"
                    alt="3s-logo-icon-only-1@2x"
                    className="size-24"
                    style={{ transform: "rotateZ(45deg)" }}
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="text-2xl md:text-3xl font-bold">
                    Pay with 3S
                  </h3>
                  <h4 className="text-gray-600 font-medium">
                    The payment you make everyday{" "}
                    <span className="uppercase font-bold text-black">
                      made secure
                    </span>
                  </h4>
                </div>
                <p className="text-gray-600 font-medium text-[14px]">
                  With just one tap, you're securing your online payment against
                  transaction fraud.
                </p>
                <a
                  href="#howitworks"
                  type="button"
                  className="w-fit capitalize inline-flex items-center gap-x-2 rounded-md bg-color-primary-bright-jade px-4 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-color-primary-bright-jade/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-primary-bright-jade"
                >
                  <FaChevronDown className="-ml-0.5 size-5" />
                  See how we do it
                </a>
              </div>
              <motion.div
                style={{
                  y: smoothTranslateY,
                }}
                className="hidden md:block"
              >
                <img src="/phone mockup.png" alt="phone mockup" />
              </motion.div>
            </section>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-5xl space-y-28">
          <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            <HowItWorksCard
              title="Standard payment methods"
              desc="Our platform allows seamless integration into your favourite online shopping experiences without introducing additional checkout steps for you."
              color="blue"
            />
            <HowItWorksCard
              title="Trusted partners"
              desc="With our list of trusted partners, your payments are secured and protected from end-to-end, ensuring you get your goods, and the stores get their money."
              color="sky"
            />
            <HowItWorksCard
              title="Low commitment usage"
              desc="You don't need a 3 Shepherd account to secure your payment. Just secure your payment with 3 Shepherd and rest easy knowing your funds are safe!"
              color="jade"
            />
            <div id="howitworks" />
          </section>
          <section className="flex items-center flex-col space-y-8">
            <div className="text-center">
              <h3 className="text-4xl md:text-[3rem] font-bold mb-4">
                How it works
              </h3>
              <h4 className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
                We work with verified user banking accounts and
                transaction-based escrow accounts.
              </h4>
            </div>

            <div className="px-4 flex justify-center py-2 w-auto lg:w-[56rem] text-white bg-color-primary-bright-jade rounded-full text-lg md:text-[1.5rem]">
              <div className="max-w-3xl text-center">
                This means we accept payment from verified buyers and
                immediately reserve the funds in a secure escrow account.
              </div>
            </div>
          </section>
          <section className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div className="space-y-4 flex justify-center">
              <img src="/Group 6152.png" alt="Group 6152" />
            </div>
            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
                Look for our logo during checkout
              </h2>
              <p className="text-lg text-gray-500 leading-relaxed">
                While shopping on your favourite online store, just tick the box
                that says "Secure my transaction against fraud by paying via 3
                Shepherd."
              </p>
            </div>
          </section>
          <div className="space-y-36">
            <section className="grid items-center grid-cols-1 md:grid-cols-2 gap-8">
              <div className="order-2 md:order-1">
                <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
                  Your payment gets immediately secured
                </h2>
                <p className="text-lg text-gray-500 leading-relaxed">
                  We put your payment in a temporary Escrow account while the
                  merchant processes your order for shipment, securing your
                  money against online transaction fraud.
                </p>
              </div>
              <div className="space-y-4 order-1 md:order-2">
                <img src="/correct-segment-2.svg" alt="correct-segment-2" />
                <img src="/correct-segment-1.svg" alt="correct-segment-1" />
              </div>
            </section>
            <section className="max-w-2xl mx-auto text-center space-y-6">
              <div className="flex justify-center">
                <img src="/group-1@2x.png" alt="group-1@2x" className="h-28" />
              </div>
              <div>
                <h2 className="text-3xl md:text-4xl font-bold mb-4">
                  Accept delivery, and that's it!
                </h2>

                <p className="text-lg text-gray-500 leading-relaxed">
                  Once you have received your purchase, your transaction is
                  completed. Just check for any defects or issues and you're
                  ready to for your next secure online transaction!
                </p>
              </div>
            </section>
            <section className="space-y-8 text-center">
              <div>
                <h3 className="text-4xl md:text-[3rem] font-bold mb-4">
                  Powerful backing
                </h3>
                <h4 className="text-lg text-gray-600 max-w-3xl mx-auto">
                  Our partnerships with Microsoft and Nvidia ensure that our
                  customers' transactions will remain secure as technology
                  develops.
                </h4>
              </div>
              <div className="flex flex-col lg:flex-row items-center justify-center gap-12">
                <img src="/image-1@2x.png" alt="image-1@2x" className="h-48" />
                <img
                  src="/nvidiainceptionprogrambadgergbforscreen-1@2x.png"
                  alt="nvidiainceptionprogrambadgergbforscreen-1@2x"
                  className="h-48"
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <section
        style={{ backgroundImage: "url(/background-waves-1@2x.png)" }}
        className="bg-cover bg-no-repeat h-[50vh] sm:h-[70vh]"
      >
        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
          <div className="mx-auto max-w-5xl h-full">
            <div className="grid grid-row-1 md:grid-rows-2 space-y-44 h-full">
              <section className="grid grid-cols-1 md:grid-cols-2">
                <div />
                <div className="text-center space-y-8">
                  <h3 className="text-3xl font-bold text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                    Want to stay in the loop?
                  </h3>
                  <div>
                    <a
                      href="/register"
                      className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <FaChevronRight className="-ml-0.5 size-5" />
                      Join The Waiting List
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-full flex justify-center">
                <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
                  <h4 className="font-bold text-2xl">
                    Connect with us on social media
                  </h4>
                  <div className="flex items-center gap-8 mt-4">
                    <a
                      href="https://www.linkedin.com/company/3-shepherd/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="size-7"
                    >
                      <img alt="LinkedIn" src="/linekdin-icon.svg" />
                    </a>
                    <a
                      href="https://www.youtube.com/@3-Shepherd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className="size-8" />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>
      <ChatbotPopup />
    </div>
  );
};

export default Home;
