import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { FaYoutube } from "react-icons/fa";

const TeamMember = ({ imageSrc, name, role, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className="hidden md:flex flex-col md:flex-row items-center justify-center gap-8 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <motion.div
          className="flex flex-col items-center"
          initial={{ x: 0 }}
          animate={{ x: isHovered ? -50 : 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 25,
            duration: 0.6,
          }}
        >
          <div className="relative w-72 h-72">
            <img
              className="rounded-full w-full h-full object-contain"
              alt={name}
              src={imageSrc}
            />
          </div>
          <h3 className="text-xl font-semibold sm:text-2xl">{name}</h3>
          <p className="text-gray-600 mt-1">{role}</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{
            opacity: isHovered ? 1 : 0,
            x: isHovered ? 0 : 50,
          }}
          exit={{ opacity: 0, x: 50 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 25,
            duration: 0.6,
          }}
          className={`absolute top-[20%] md:left-[calc(50%+5rem)]`}
          style={{
            display: isHovered ? "block" : "none",
          }}
        >
          <p className="text-sm/relaxed text-gray-600">{description}</p>
        </motion.div>
      </div>
      <div className="space-y-8 md:hidden block">
        <div className="flex flex-col items-center">
          <div className="relative w-72 h-72">
            <img
              className="rounded-full w-full h-full object-contain"
              alt={name}
              src={imageSrc}
            />
          </div>
          <h3 className="text-xl font-semibold sm:text-2xl">{name}</h3>
          <p className="text-gray-600 mt-1">{role}</p>
        </div>
        <div>
          <p className="text-sm/relaxed text-gray-600">{description}</p>
        </div>
      </div>
    </>
  );
};

const AboutUs = () => {
  return (
    <>
      <div className="space-y-32">
        <section
          style={{
            backgroundImage: "url(saschabosshardgfmeeliy7r8unsplash-1@2x.png)",
          }}
          className="bg-no-repeat bg-cover bg-center min-h-screen"
        >
          <div className="px-4 md:px-10 min-h-screen flex flex-col justify-center">
            {/* Left-Aligned About Us Section */}
            <div className="w-full flex justify-start">
              <div>
                <h1 className="text-4xl md:text-6xl font-bold text-white mb-8">
                  About us
                </h1>
                <div className="flex gap-8">
                  <a
                    href="#mission"
                    className="cursor-pointer text-white hover:text-white/80 font-medium transition-colors"
                  >
                    Our Mission
                  </a>
                  <a
                    href="#team"
                    className="cursor-pointer text-white hover:text-white/80 font-medium transition-colors"
                  >
                    The Team
                  </a>
                </div>
              </div>
            </div>

            {/* Left-Aligned Our Mission Section */}
            <div className="w-full flex justify-start mt-16">
              <div className="max-w-3xl p-6 md:p-8 lg:p-10 backdrop-blur-md bg-white/30 rounded-xl shadow-lg text-left">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                  Our mission
                </h2>
                <p className="text-xl font-semibold text-gray-900 mb-2">
                  We are driven by a passion for making online transactions
                  safer and easier to use.
                </p>
                <p className="text-sm text-gray-700">
                  Based in Dubai, UAE, 3 Shepherd aims to change the world of
                  online commerce.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-4xl space-y-28">
            <section className="grid md:grid-cols-[minmax(0,1fr)_250px] items-center gap-10">
              <div className="space-y-8">
                <h2 className="text-xl md:text-[22px] font-medium leading-normal">
                  The global e-commerce industry is booming, set to reach a
                  market value of{" "}
                  <span className="font-bold"> US$5.56 trillion by 2027.</span>
                </h2>
                <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                  However, a major challenge it faces is the rise in fraud and
                  financial crimes, which erode trust among consumers. In 2020,
                  online scams made up{" "}
                  <span className="font-bold text-black">
                    38% of reported fraud cases worldwide{" "}
                  </span>
                  , increasing from 24% pre-pandemic. Although this has slightly
                  decreased, the industry still suffers significant losses, with
                  over US$40 billion lost to online payment fraud in 2022 alone.
                </p>
              </div>
              <div className="flex justify-center">
                <img src="/earth.png" alt="earth" className="size-64" />
              </div>
            </section>
            <section className="grid md:grid-cols-[250px_minmax(0,1fr)] items-center gap-10">
              <div className="flex justify-center order-2 md:order-1">
                <img
                  src="/shield_image.png"
                  alt="shield_image"
                  className="h-64 w-72"
                />
              </div>
              <div className="space-y-8 order-1 md:order-2">
                <h2 className="text-end text-xl md:text-[22px] font-medium leading-normal">
                  At 3-Shepherd, we’re dedicated to
                  <span className="font-bold">
                    {" "}
                    tackling these challenges head-on.
                  </span>
                </h2>
                <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                  We understand that people hesitate to engage in e-commerce due
                  to the perceived risks and high costs of traditional banking
                  services. That’s why we’ve developed a cutting-edge
                  Transaction Lifecycle Management (TLM) solution.
                </p>
              </div>
            </section>
            <section className="space-y-8">
              <h2 className="text-xl md:text-[22px] font-bold leading-normal">
                Our TLM solution focuses on:
              </h2>
              <ol className="px-8 md:px-12 list-decimal text-base md:text-lg text-gray-600 leading-relaxed">
                <li>
                  Providing clear visibility into transactions for all parties
                  involved.
                </li>
                <li>
                  Empowerment: Equipping stakeholders with tools to manage fraud
                  and financial risks effectively.
                </li>
                <li>
                  Seamless Integration: Creating a smooth process that enhances
                  the customer experience and secures transactions.
                </li>
              </ol>
              <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                Powered by Blockchain, Machine Learning, and AI, our solution
                seamlessly integrates with various services like sales
                platforms, payment gateways, and courier networks. It detects
                and manages fraud incidents in real-time, safeguarding
                transactions. Beyond fraud prevention, our TLM solution boosts
                sales, offers valuable business insights, and increases
                revenues.
              </p>
              <h2 className="text-xl md:text-[22px] font-bold leading-normal">
                Our ultimate goal is to restore trust and confidence in the
                e-commerce industry.
              </h2>
            </section>
            <section className="flex flex-col justify-center items-center space-y-8">
              <div>
                <img src="/icon_aboutus.png" alt="icon_aboutus" />
              </div>
              <h2 className="text-center text-xl md:text-[22px] font-bold leading-normal">
                The core of 3-Shepherd’s mission is to ensure the safe and
                secure end-to-end facilitation and execution of e-commerce
                transactions, both domestic and cross-border.
              </h2>
              <div className="flex flex-col items-end space-y-4">
                <img
                  src="/component-1.svg"
                  alt="component-1"
                  className="w-2/5"
                />
                <img
                  src="/component-2.svg"
                  alt="component-2"
                  className="w-1/2"
                />
                <img
                  src="/correct-segment.svg"
                  alt="correct-segment"
                  className="w-2/5"
                />
              </div>
              <div id="team" />
            </section>
            <section>
              <div className="text-center space-y-4">
                <h2 className="text-3xl md:text-4xl font-bold leading-normal">
                  The Team
                </h2>
                <p className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto px-4">
                  Our ultimate goal is to restore trust and confidence in the
                  e-commerce industry.
                </p>
              </div>
              <div className="space-y-8">
                <TeamMember
                  imageSrc="/image 3.png"
                  name="Hassan Tajuddin"
                  role="CEO"
                  description="Hassan is a seasoned treasury professional with over 15 years of banking experience in diverse markets, including roles at Dubai Islamic Bank and Standard Chartered Bank. He holds a BBAMIS degree and an MSc in Finance, with a thesis focusing on Microscopic Simulation. Hassan has led teams in developing innovative technology-driven financial solutions, including serving as team lead for HBL's FX trading platform, HBL Infinity. He's also skilled in technology, with achievements in the Microsoft Imagine Cup and holding the title of Certified Meta Developer."
                />
                <TeamMember
                  imageSrc="/image 6.png"
                  name="Pieter Vos"
                  role="Strategy Advisor | Board Member"
                  description="Pieter is a commercially focused business leader with more than 15 years of experience in the financial sector and has lead teams across more than 25 countries. He holds a B.Sc in Digital Technology and Design from the Technological University of Dublin; and an Masters in Business Administration from the University of Illinois with a major in Mergers & Acquisitions and Entrepreneurship & Strategic Innovation."
                />
                <TeamMember
                  imageSrc="/ahmed-ayob.jpg"
                  name="Ahmed Ayob"
                  role="Advisor"
                  description="Ahmed is a seasoned serial entrepreneur with a proven track record in fintech, digital innovation, and championing financial inclusion. He co-founded Cointel, the world’s first prepaid mobile top-up via credit card, and Simplus in partnership with MasterCard—both later acquired by Vodacom Ltd. With a strong focus on scalable financial solutions, he has built and led ventures that bridge traditional finance with digital transformation, expanding access to financial services. His expertise spans technology-driven business development, payment ecosystem optimization, and strategic growth in emerging markets."
                />
                <TeamMember
                  imageSrc="/image 7.png"
                  name="Janico Steyn"
                  role="UI/UX Designer"
                  description="Janico is a Google Certified User Interface and User Experience (UI & UX) design and research professional with a Diploma in Graphic Design from the University of Cape Town and 12 years of experience. His experience in the creative and digital arts landscape over the past dozen years include Graphic Design & Sketch Artistry, Videography & Editing, Music Production and more."
                />
              </div>
            </section>
          </div>
        </section>
      </div>
      {/* Footer Section - Moved Outside the Main Content */}
      <footer
        style={{ backgroundImage: "url(/background-waves-1@2x.png)" }}
        className="bg-cover bg-no-repeat h-[50vh] sm:h-[70vh] mt-16"
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
          <div className="mx-auto max-w-5xl h-full">
            <div className="grid grid-row-1 md:grid-rows-2 space-y-44 h-full">
              <section className="grid grid-cols-1 md:grid-cols-2">
                <div />
                <div className="text-center space-y-8">
                  <h3 className="text-3xl font-bold text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                    Want to stay in the loop?
                  </h3>
                  <div>
                    <a
                      href="/register"
                      className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-[14px] font-semibold text-white shadow-sm hover:bg-indigo-500"
                    >
                      <FaChevronRight className="-ml-0.5 size-5" />
                      Join The Waiting List
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-full flex justify-center">
                <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
                  <h4 className="font-bold text-2xl">
                    Connect with us on social media
                  </h4>
                  <div className="flex items-center gap-8 mt-4">
                    <a
                      href="https://www.linkedin.com/company/3-shepherd/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="size-7"
                    >
                      <img alt="LinkedIn" src="/linekdin-icon.svg" />
                    </a>
                    <a
                      href="https://www.youtube.com/@3-Shepherd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className="size-8" />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AboutUs;
