import React, { useState } from "react";
import OrdersList from "./OrdersList";
import Navbar from "../navbar/Navbar";
import List from "../table/ShipmentTable";
import RequestPickup from "./RequestPickup";
import { Container, Typography, Paper, Box } from '@mui/material';
import Sidebar from "../sidebar/Sidebar";

const steps = [
  { id: 1, label: "Create Shipment" },
  { id: 2, label: "Print Order Label" },
  { id: 3, label: "Request Shipment" },
];

const OrderFlowPage = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <OrdersList />;
      case 2:
        return <List />;
      case 3:
        return <RequestPickup />;
      default:
        return <OrdersList />;
    }
  };

  return (
    <Box className="flex">
      <Sidebar />
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="xl" sx={{mt: 4, mb:4}}>
          {/* Progress Tracker */}
          <div className="flex items-center mb-6">
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={`flex items-center ${
                  index !== steps.length - 1 ? "flex-1" : ""
                }`}
              >
                <div className="flex items-center">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full text-white ${
                      currentStep === step.id
                        ? "bg-blue-900"
                        : currentStep > step.id
                        ? "bg-green-500"
                        : "bg-gray-300"
                    }`}
                  >
                    {step.id}
                  </div>
                  <span
                    className={`ml-4 text-sm ${
                      currentStep === step.id
                        ? "text-blue-900 font-bold"
                        : "text-gray-500"
                    }`}
                    style={{ padding: "2px 2px" }} // Added padding for label
                  >
                    {step.label}
                  </span>
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className={`flex-1 h-1 mx-2 ${
                      currentStep > step.id
                        ? "bg-green-500"
                        : "bg-gray-300"
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>

          {/* Step Content */}
          <div>{renderStepContent()}</div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-6">
            <button
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
              onClick={handlePrevious}
              disabled={currentStep === 1}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 bg-blue-900 text-white rounded disabled:opacity-50"
              onClick={handleNext}
              disabled={currentStep === steps.length}
            >
              Next
            </button>
          </div>
        </Container>
      </Box>
    </Box>
  );
};

export default OrderFlowPage;