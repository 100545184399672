import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Loader2 } from "lucide-react";
import api from "../../services/api";
import { toast } from "react-toastify";

const phoneRegex = /^(\+\d{1,3}\s?)((\(?\d{3}\)?[\s-]?)?[\d\s-]{7,})$/;

const formSchema = z.object({
  fullName: z.string().min(1, "Full name is required"),
  email: z.string().email("Invalid email").or(z.literal("")),
  phone: z
    .string()
    .regex(phoneRegex, "Invalid phone number")
    .optional()
    .or(z.literal("")),
  referralCode: z.string().optional(),
});

const WaitList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      phone: "",
      referralCode: "",
    },
  });

  const email = watch("email");
  const phone = watch("phone");

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post("/waitlist/add", data);
      toast.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);

        toast.error(error.response.data.error);
      } else {
        console.error("Unexpected error:", error.message);
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Join the Waitlist
        </h1>
        <p className="text-[14px] text-zinc-500">
          Be the first to know when we go live!
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-8">
        <div className="space-y-2">
          <label
            htmlFor="fullName"
            className="text-[14px] font-medium leading-none"
          >
            Full Name <span className="text-red-500">*</span>
          </label>
          <input
            id="fullName"
            type="text"
            {...register("fullName")}
            className="flex h-10 w-full rounded-md border border-zinc-200 bg-white px-3 py-2 text-[14px] transition-colors file:border-0 file:bg-transparent file:text-[14px] file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          />
          {errors.fullName && (
            <p className="text-[14px] font-medium text-red-500">
              {errors.fullName.message}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <label
            htmlFor="email"
            className="text-[14px] font-medium leading-none"
          >
            Email <span className="text-red-500">*</span>
          </label>
          <input
            id="email"
            type="email"
            {...register("email")}
            className="flex h-10 w-full rounded-md border border-zinc-200 bg-white px-3 py-2 text-[14px] transition-colors file:border-0 file:bg-transparent file:text-[14px] file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          />
          {errors.email && (
            <p className="text-[14px] font-medium text-red-500">
              {errors.email.message}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <label
            htmlFor="phone"
            className="text-[14px] font-medium leading-none"
          >
            Phone Number
          </label>
          <input
            id="phone"
            type="tel"
            placeholder="+1 (555) 000-0000"
            {...register("phone")}
            className="flex h-10 w-full rounded-md border border-zinc-200 bg-white px-3 py-2 text-[14px] transition-colors file:border-0 file:bg-transparent file:text-[14px] file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          />
          {errors.phone && (
            <p className="text-[14px] font-medium text-red-500">
              {errors.phone.message}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <label
            htmlFor="referralCode"
            className="text-[14px] font-medium leading-none"
          >
            Referral Code
          </label>
          <input
            id="referralCode"
            type="text"
            {...register("referralCode")}
            className="flex h-10 w-full rounded-md border border-zinc-200 bg-white px-3 py-2 text-[14px] transition-colors file:border-0 file:bg-transparent file:text-[14px] file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          />
          {errors.referralCode && (
            <p className="text-[14px] font-medium text-red-500">
              {errors.referralCode.message}
            </p>
          )}
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex w-full items-center justify-center rounded-md bg-zinc-900 px-4 py-2 text-[14px] font-medium text-white transition-colors hover:bg-zinc-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
        >
          {isLoading ? <Loader2 className="animate-spin size-4" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default WaitList;
