import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import Navbar from "../../../components/navbar/Navbar";
import AdminSidebar from "../../../components/sidebar/AdminSidebar";
import UserTable from "./UserTable"; // Adjust the import path to your UserTable component
import UserList from "../../../components/Merchant/UserList";
import MerchantList from "../../../components/Merchant/MerchantList";

const AdminUserList = () => {
  return (
    <main className="flex">
      <AdminSidebar />
      <div className="flex flex-col flex-1 relative">
        <Navbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Users
              </Typography>
              <UserList />
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Merchants
              </Typography>
              <MerchantList />
            </Box>
          </Paper>
        </Container>
      </div>
    </main>
  );
};

export default AdminUserList;