import React, { useState } from "react";
import {
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Stack,
  TextField,
  Button,
  Box,
} from "@mui/material";
import api from "../../../services/api";
import { toast } from "react-toastify";

const BankDetails = ({ user, setActiveStep }) => {
  const [iban, setIban] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (!iban) {
      setError("Please enter your IBAN");
      return;
    }

    setSubmitLoading(true);
    try {
      const response = await api.post("/auth/merchant-iban", {
        email: user.email,
        iban,
      });

      if (response.data.success === true) {
        toast.success("Bank details added successfully");
        setActiveStep(2);
      } else {
        toast.error(response.data.message || "Failed to add bank details");
      }
    } catch (error) {
      setError("Error adding bank details");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Bank Details
        </Typography>
        <Stack spacing={2}>
          <TextField
            label="IBAN"
            value={iban}
            onChange={(e) => setIban(e.target.value)}
            fullWidth
            size="small"
            rows={2}
            required
          />
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={submitLoading}
            sx={{
              bgcolor: "#08C989",
              "&:hover": { bgcolor: "#07b37b" },
              minWidth: 180,
              height: 36,
              position: "relative",
            }}
          >
            {submitLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              "Continue"
            )}
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
};

export default BankDetails;